// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-recipe-js": () => import("./../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-tag-js": () => import("./../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kategorie-js": () => import("./../src/pages/kategorie.js" /* webpackChunkName: "component---src-pages-kategorie-js" */),
  "component---src-pages-ostatnie-js": () => import("./../src/pages/ostatnie.js" /* webpackChunkName: "component---src-pages-ostatnie-js" */),
  "component---src-pages-ostronie-js": () => import("./../src/pages/ostronie.js" /* webpackChunkName: "component---src-pages-ostronie-js" */),
  "component---src-pages-szukaj-js": () => import("./../src/pages/szukaj.js" /* webpackChunkName: "component---src-pages-szukaj-js" */),
  "component---src-pages-tagi-js": () => import("./../src/pages/tagi.js" /* webpackChunkName: "component---src-pages-tagi-js" */),
  "component---src-pages-wszystkie-js": () => import("./../src/pages/wszystkie.js" /* webpackChunkName: "component---src-pages-wszystkie-js" */)
}

